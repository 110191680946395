
#GraphSubitle {
    font-size: 24px;
    margin-left: 24px;
    margin-top: 24px;
    font-weight: bolder;
}

    .statsTable {
        margin-top: 2em;
    }

.statsTable>table>thead>tr>th{
    border-right: none;
}
#graphContainer {
    box-sizing: content-box;
}
#graphId {
    height: 45vh;
    box-shadow: 0 0 5px 2px var(--shadowColor);
    background-color: var(--primColor);
    resize:both;
    overflow:auto; /* something other than visible */
}
#graphId2 {
    height: 45vh;    border: 4px solid var(--shadowColor);
    background-color: var(--primColor);
    resize:both;
    overflow:auto; /* something other than visible */
}

#graphIdPrintMode {
    height: 45vh;
    border: 4px solid black;
    background-color: white;
    resize:both;
    overflow:auto; /* something other than visible */
}

.title {
    font-weight: 600;
    font-size: 22pt;
    padding: .5em;
    margin-bottom: 1em;
    box-shadow: 0 0 5px 2px var(--shadowColor);
}