// Your variable overrides

$sidebar-bg-color: var(--altBackgroundColor);
$submenu-bg-color: var(--altBackgroundColor);
$submenu-bg-color-collapsed: var(--altBackgroundColor);
$sidebar-color: var(--titleColor);
$highlight-color: rgba(255, 255, 255, 0.95);
$icon-bg-color: rgba(255, 255, 255, 0.05);
$icon-size: 35px;

@import '../../node_modules/react-pro-sidebar/dist/scss/styles';

.logotext {
  border-bottom: 4px solid rgba(255, 255, 255, 0.25);
}
#header .closemenu {
  position: absolute;
  right: 0;
  z-index: 9999;
  line-height: 20px;
  border-radius: 50%;
  font-weight: bold;
  font-size: 22px;
  top: 55px;
  cursor: pointer;
}
#header .pro-sidebar {
  width: 100%;
  min-width: 100%;
  height: 100vh;
}
#header .pro-sidebar.collapsed {
  width: 110px;
  min-width: 110px;
}
#header .pro-sidebar-inner {
  box-shadow: 0.5px 0.866px 2px 0px rgba(0, 0, 0, 0.15);
}
#header .pro-sidebar-inner .pro-sidebar-layout {
  overflow-y: hidden;
  height: 100vh;
}
#header .pro-sidebar-inner .pro-sidebar-layout .logotext p {
  font-size: 20px;
  padding: 0 20px;
  font-weight: bold;
}
#header .pro-sidebar-inner .pro-sidebar-layout ul {
  padding: 0 5px;
}
#header .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item {
  margin: 10px 0px;
  font-weight: bold;
}
#header .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item .pro-icon-wrapper {
  border-radius: 3px;
}
#header .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item .pro-icon-wrapper .pro-item-content {
}
#header .logo {
  padding: 20px;
}
@media only screen and (max-width: 720px) {
  html {
    overflow: hidden;
  }
}
